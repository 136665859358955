// export const apiUrl = 'http://chairsyde-api.test/api/clinic-widget'
// export const apiUrl = 'https://api.chairsyde.com/api/clinic-widget'
export const pureApiUrl = process.env.VUE_APP_API_URL

export const apiUrl = process.env.VUE_APP_API_URL + '/api/clinic-widget'

export const maxFileSize = process.env.VUE_APP_UPLOAD_FILE_SIZE || 10

export const stripeToken = process.env.VUE_APP_STRIPE_TOKEN

export const mixpanelId = process.env.VUE_APP_MIXPANEL_ID || '5e9b46cc98832088db2e2ab6fa88006d'
