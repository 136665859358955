import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import 'vue-datetime/dist/vue-datetime.css'
import mixpanel from 'mixpanel-browser'
import { mixpanelId } from '@/config'
mixpanel.init(mixpanelId, { debug: true, ignore_dnt: true, batch_size: 1 })

require('./style/style.scss')

Vue.config.productionTip = false

Vue.prototype.$vueEventBus = new Vue()

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
