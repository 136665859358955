<template>
  <WizardWrapper>
    <template v-slot:stepper>
      <StepperHeader :value="3"></StepperHeader>
    </template>
    <template v-slot:calendar>
      <!--        <DatePick/>-->
      <datepicker
        maximum-view="day"
        monday-first="monday-first"
        full-month-name="full-month-name"
        v-model="selectedDate"
        :disabled-dates="{days: [0,1,2,3,4,5,6]}"
        :inline="true"
        calendar-class="ui-calendar"
        wrapper-class="ui-calendar-wr mobile-hide"
      ></datepicker>
      <label class="mobile-only ui-label">Current Appointment:</label>
      <div class="ui-label-time-select mobile-only">
        {{ wizardData.selectedSlot.start_timestamp | moment('MMMM DD dddd') }} &nbsp;at&nbsp; {{ wizardData.selectedSlot.start_timestamp | moment('HH:mm') }} - {{ wizardData.selectedSlot.end_timestamp | moment('HH:mm') }}
      </div>
    </template>
    <template v-slot:infoDoc>
      <DoctorInfo
        :doc-name="wizardData.selectedDoctor.name"
        :photo="wizardData.selectedDoctor.photo_url"
        :doc-title="wizardData.selectedDoctor.title"
      />
    </template>
    <template v-slot:infoTime>
      <div class="ui-label-time-select">
        {{ wizardData.selectedSlot.start_timestamp | timeslot }} - {{ wizardData.selectedSlot.end_timestamp | timeslot}}
      </div>
    </template>
    <template v-slot:form>
      <!-- <div class="form-wr__history"> -->
        <!-- <div class="ui-form-heading">Image upload</div> -->
        <div
          class="top_text"
        >Please provide photos if that will help prepare for the consultation, follow the guide below:<br><br><span style="display:none" :class="{'error_text':(maxFileSize<fileSize)}">Max file size : {{maxFileSize}}MB ( {{fileSize}}MB / {{maxFileSize}}.00MB )</span></div>
        <div class="upload_grid">
          <div v-for="num in 6" :key="num" class="upload_cube" :class="{'selected_image':wizardData.images[num],'loading':wizardData.images_state[num] ,'disable_image':(maxFileSize<fileSize && !wizardData.images[num])}" :style="{'background-image': 'url(' + (wizardData.images[num]?wizardData.images[num]:require('@/assets/upload-'+num+'.png')) + ')'}" @click.self="upload(num)">
            <radial-progress-bar v-if="wizardData.images_state[num] && !wizardData.images_error[num]" innerStrokeColor="#f2b0da" stopColor="#dd2c95" startColor="#dd2c95" :innerStrokeWidth="3" :strokeWidth="3" :diameter="50"
                                :completed-steps="wizardData.images_load[num]"
                                :total-steps="100">
            </radial-progress-bar>
            <div v-else @click="upload(num)">
              <transition name="fade" mode="out-in">
                <i v-if="wizardData.images_error[num]" style="color:red;font-size:30px" class="fas fa-exclamation-triangle"></i>
              </transition>
            </div>
            <div class="bottom_bar">
              <i class="remove fas fa-minus" @click="removeImage(num)" v-if="wizardData.images[num]" ></i>
              <img class="camera" @click="upload(num)" v-else src="../../assets/add_a_photo-24px.svg" />
            </div>
          </div>
          <input id="file" type="file" style="display:none" accept="image/*"/>
        </div>
          <image-uploader
              style="display:none"
              :debug="1"
              :maxSize="100"
              :quality="1"
              :autoRotate=false
              outputFormat="verbose"
              :preview=false
              accept="image/*"
              doNotResize="['gif', 'svg']"
              @input="setImage"
            ></image-uploader>
        <div v-if="clinicData.settings && (clinicData.settings.upload_at_least_3_images === 1)" class="bottom_text text-danger">Please upload at least 3 photos.</div>
        <div v-else  class="bottom_text">This step is optional.</div>

      <!-- </div> -->
    </template>
    <template v-slot:buttons>
      <router-link
        @click.native="goPrev()"
        :disabled="loading"
        to="/select-booking/step2"
        tag="button"
        class="ui-button ui-button--secondary"
      >Back</router-link>
      <router-link
         @click.native="goNext()"
        to="/select-booking/step3"
        :disabled="maxFileSize<fileSize || loading || (clinicData.settings && clinicData.settings.upload_at_least_3_images === 1 && getUploadedImageCount < 3)"
        tag="button"
        class="ui-button ui-button--primary"
      >Next</router-link>
    </template>
  </WizardWrapper>
</template>

<script>
import WizardWrapper from '@/components/layout/WizardWrapper'
import DoctorInfo from '@/components/ui/DoctorInfo'
import StepperHeader from '@/components/ui/StepperHeader'
import Datepicker from 'vuejs-datepicker'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import common from '@/api/common'
import { maxFileSize } from '../../config'
import RadialProgressBar from 'vue-radial-progress'
import ImageUploader from 'vue-image-upload-resize'
import mixpanel from 'mixpanel-browser'

export default {
  name: 'StepThree',
  components: {
    WizardWrapper,
    Datepicker,
    DoctorInfo,
    StepperHeader,
    RadialProgressBar,
    ImageUploader
  },
  computed: {
    ...mapGetters(['wizardData', 'historyList', 'clinicData', 'getUploadedImageCount']),
    fileSize: function () {
      var fileSize = 0
      for (const key in this.wizardData.images_size) {
        fileSize += this.wizardData.images_size[key]
      }
      return parseFloat((fileSize / (1024 * 1024)).toFixed(2))
    },
    loading: function () {
      var final = false
      for (let index = 1; index < 7; index++) {
        final = final || this.wizardData.images_state[index]
      }
      return final
    }
  },
  filters: {
    timeslot: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'loadHistoryList', 'saveHistoryForm', 'clearImage', 'uploadImage', 'uploadImageBas64']),
    removeImage (val) {
      this.clearImage(val)
    },
    upload (val) {
      if (this.maxFileSize >= this.fileSize || this.wizardData.images[val]) {
        this.selectedImage = val
        document.querySelector('#fileInput').click()
        // document.querySelector('#file').click()
      }
    },
    setImage (val) {
      this.uploadImageBas64({ id: this.selectedImage, file: val.dataUrl })
      document.querySelector('#fileInput').value = ''
    }
  },
  mounted () {
    this.selectedDate = moment(this.wizardData.selectedDate).toDate()
    this.loadHistoryList()
    if (!common.isEmpty(this.wizardData.historyFormData)) {
      this.form = this.wizardData.historyFormData
    }
    var vue = this
    document.querySelector('#file').onchange = function () {
      var input = document.querySelector('#file')
      if (input.files && input.files[0]) {
        vue.uploadImage({ id: vue.selectedImage, file: input.files[0] })
        document.querySelector('#file').value = ''
      }
    }
    mixpanel.track('booking_widget.step_2.image_upload', {
    })
  },
  data () {
    return {
      maxFileSize: maxFileSize,
      selectedImage: 0,
      selectedDate: new Date(),
      form: {},
      valid: true,
      formOut: {},
      show_danger: false
    }
  },
  watch: {
    form: {
      handler: function (val) {
        var final = true
        for (const key in this.form) {
          if (this.form[key] === '') {
            final = false
            break
          }
        }
        this.valid = final
        this.saveHistoryForm(this.form)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style/style";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-wr__history {
  display: block;
  position: relative;
  padding-right: 4px;
  @media (min-width: $breakpoint-tablet) {
  }
}
.stepper-mobile .step:first-of-type {
  margin-left: calc(50% - 250px);
}
.top_text {
  color: #a3a3a3;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  line-height: 12px;
}
.bottom_text {
  color: #a3a3a3;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
}
.selected_image{
  background-size: cover !important;
}

.disable_image {
  opacity: 0.5 !important;
  cursor: auto !important;
}

.error_text{
  color: red;
  font-weight: bold;
}

.upload_grid {
  margin-top: 27px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;
  margin-left: 10%;
  margin-right: 10%;
  align-items: stretch;
  height: 70%;

  @media screen and (max-width: $breakpoint-tablet) {
      grid-template-columns: auto auto !important;
  }

  .upload_cube {
    background: #fcfcfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 10px;
    display: grid;
    grid-template-rows: auto 20%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    background-position: center;
    background-size: 52%;

    div{
      justify-self: center;
      align-self: center;
    }

    &.loading {
      opacity: 0.4;
      position: relative;
    }

    @media screen and (max-width: $breakpoint-tablet) {
      height: 180px;
      background-size: 40%;
    }

    .bottom_bar{
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .remove {
        align-self: flex-end;
        color: white;
        font-size: 15px;
        font-weight: bold;
        background: #dd2c95;
        width: 25px;
        height: 25px;
        align-content: center;
        display: grid;
        border-radius: 20px;
        box-shadow: 0 0 0px 3px #f0bddc;
        cursor: pointer;
      }

      .camera {
        align-self: flex-end;
        // position: relative;
        // right: 0;
        // bottom:0;
      }
    }
  }
}
</style>
