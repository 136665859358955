import Vue from 'vue'
import Router from 'vue-router'
import BookAppSelectDoc from '@/components/BookAppSelectDoc'
import BookAppWelcome from '@/components/BookAppWelcome'
import BookAppWizard from '@/components/BookAppWizard'
import BookAppSuccess from '@/components/BookAppSuccess'
import BookAppError from '@/components/BookAppError'
import BookAppStripeError from '@/components/BookAppStripeError'
import StepOne from '@/components/ui/StepOne'
import StepTwo from '@/components/ui/StepTwo'
import StepTwoImageUpload from '@/components/ui/StepTwo-ImageUpload'
import StepThree from '@/components/ui/StepThree'
import StepFour from '@/components/ui/StepFour'
import StepFive from '@/components/ui/StepFive'
import ProcessError from '@/components/BookAppErrorPage'
import NotAllowed from '../components/NotAllowed'
import SettingsChangedError from '../components/SettingsChangedError'
import LandingPage from '@/components/LandingPage'
import skipDetector from '@/router/skipDetector'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'Main',
      beforeEnter: skipDetector,
      component: BookAppWelcome
    },
    // {
    //   path: '*',
    //   redirect: '/'
    // },
    {
      path: '/select-doc',
      name: 'Select',
      beforeEnter: skipDetector,
      component: BookAppSelectDoc
    },
    {
      path: '/select-booking',
      name: 'Wizard',
      component: BookAppWizard,
      children: [
        {
          path: 'step1',
          beforeEnter: skipDetector,
          component: StepOne
        },
        {
          path: 'step2',
          beforeEnter: skipDetector,
          component: StepTwo
        },
        {
          path: 'step2-imageupload',
          beforeEnter: skipDetector,
          component: StepTwoImageUpload
        },
        {
          path: 'step3',
          beforeEnter: skipDetector,
          component: StepThree
        },
        {
          path: 'step4',
          beforeEnter: skipDetector,
          component: StepFour
        },
        {
          path: 'step5',
          beforeEnter: skipDetector,
          component: StepFive
        }
      ]
    },
    {
      path: '/complete',
      name: 'Success',
      beforeEnter: skipDetector,
      component: BookAppSuccess
    },
    {
      path: '/error',
      name: 'Error',
      component: BookAppError
    },
    {
      path: '/process-error',
      name: 'ProcessError',
      component: ProcessError
    },
    {
      path: '/card-error',
      name: 'CardError',
      component: BookAppStripeError
    },
    {
      path: '/404',
      name: '404',
      component: BookAppError
    },
    {
      path: '/no-allowed',
      name: '404',
      component: NotAllowed
    },
    {
      path: '/settings-error',
      name: '404',
      component: SettingsChangedError
    },
    {
      path: '/landing-page/:id',
      name: 'landing-page',
      component: LandingPage
    }
  ]
})
