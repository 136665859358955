<template>
  <ContentWrapper form-title="Something went wrong">
    <template v-slot:default>
      <div class="welcome-wr">
        <h1 >{{finalResult.message}}</h1>
        {{getErrorMessage}}
      </div>
    </template>
    <template v-slot:buttons>
      <button @click="resetWizardData ()" class="ui-button ui-button--dark">Try again</button>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'
import { mapGetters } from 'vuex'

export default {
  name: 'BookAppError',
  components: { ContentWrapper },
  computed: {
    ...mapGetters(['wizardData', 'finalResult', 'widgetId']),
    getErrorMessage: function () {
      var errorMessage = ''
      for (const key in this.finalResult.errors) {
        errorMessage = this.finalResult.errors[key][0]
      }
      return errorMessage
    }
  },
  methods: {
    resetWizardData () {
      window.location = `/?id=${this.widgetId}`
    }
  }
}
</script>

<style scoped>

</style>
