<template>
  <div id="app">
    <!-- {{wizardData.current_step}} -->
    <MainWrapper/>
  </div>
</template>

<script>
import MainWrapper from '@/components/layout/MainWrapper'
import { mapActions, mapGetters } from 'vuex'
// import Api from '@/api/api.js'

export default {
  name: 'App',
  components: {
    MainWrapper
  },
  computed: {
    ...mapGetters(['clinicData', 'wizardData'])
  },
  methods: {
    ...mapActions(['start'])
  },
  watch: {
    // clinicData: function (val) {
    //   var url = new URL(window.location)
    //   if (url.searchParams.get('id') && (!val.stripe_key || val.stripe_key.length < 1)) {
    //     this.$router.push('/card-error')
    //   }
    // }
  }
}
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  @media screen and (max-width: 767px) {
    .content-wrapper{
      padding: 20px;
    }
  }
</style>
