<template>
  <div class="master-wrapper">
    <div class="main-wrapper" v-if="$route.name != 'landing-page'">
      <!--      <ContentWrapper/>-->
      <RouterView/>
      <!--      <p :class="$store.getters['themeColor']">copyright</p>-->
      <Footer/>
    </div>
      <RouterView v-else />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Footer from '@/components/layout/Footer'

export default {
  name: 'MainWrapper',
  components: { Footer },
  computed: {
    ...mapGetters([
      'themeColor', 'wizardData'
    ])
  },
  mounted () {
    if (this.$route.name !== 'landing-page') {
      const urlObj = new URL(window.location)
      if (!urlObj.searchParams.get('id')) {
        this.$router.push('/404')
      } else {
        this.setWidgetId(urlObj.searchParams.get('id'))
        if (this.$router.currentRoute.path !== '/') {
          this.$router.push('/')
          this.start()
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setThemeColor', 'setWidgetId'
    ])
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .master-wrapper {
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    overflow: auto;
    background-color: $color-background;

    /* scrollbar parent */
    &::-webkit-scrollbar {
      width: 8px;
      background: $color-background;
      position: relative;
      display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #DEDEDE;
      position: absolute;
      margin: 25% 0 30%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #B9B9B9;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #909090;
    }
  }

  .main-wrapper {
    min-height: 100%;
    box-sizing: border-box;
    // padding: 40px 8px;
    overflow: auto;
    position: relative;

    @media (min-width: $breakpoint-tablet) {
      height: 100%;
      overflow: hidden;
      padding: 40px 50px;
      max-height: 680px;
      min-height: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      position: absolute;
      margin: 20% 0;
    }
  }
</style>
