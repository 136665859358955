import store from '@/store'

export default async (to, from, next) => {
  function getPathFromSteps (step) {
    switch (step) {
      case 1:
        return ['/', '/select-doc']
      case 2:
        return ['/', '/select-booking/step1']
      case 3:
        return ['/select-doc', '/select-booking/step2']
      case 4:
        return ['/select-booking/step1', '/select-booking/step2-imageupload']
      case 5:
        return ['/select-booking/step2', '/select-booking/step3']
      case 6:
        return ['/select-booking/step2-imageupload', '/select-booking/step4']
      case 7:
        return ['/select-booking/step3', '/select-booking/step5']
      case 8:
        return ['/select-booking/step4', '/complete', '/error']
    }
  }

  const currentStep = store.getters.wizardData.current_step

  if (!getPathFromSteps(currentStep).includes(to.path)) {
    window.location = '/?id=' + store.getters.widgetId
  } else {
    next()
  }
}
