<template>
  <WizardWrapper>
    <template v-slot:stepper>
      <StepperHeader :value="5"></StepperHeader>
    </template>
    <template v-slot:calendar>
      <!--        <DatePick/>-->
      <datepicker
        maximum-view="day" monday-first="monday-first" full-month-name="full-month-name"
        v-model="selectedDate"
        :disabled-dates="{days: [0,1,2,3,4,5,6]}"
        :inline="true"
        calendar-class="ui-calendar"
        wrapper-class="ui-calendar-wr mobile-hide"
      ></datepicker>
      <label class="mobile-only ui-label">Current Appointment:</label>
      <div class="ui-label-time-select mobile-only">{{ wizardData.selectedSlot.start_timestamp | moment('MMMM DD dddd') }} &nbsp;at&nbsp; {{ wizardData.selectedSlot.start_timestamp | moment('HH:mm') }} - {{ wizardData.selectedSlot.end_timestamp | moment('HH:mm')
        }}</div>
    </template>
    <template v-slot:infoDoc>
      <DoctorInfo :doc-name="wizardData.selectedDoctor.name" :photo="wizardData.selectedDoctor.photo_url" :doc-title="wizardData.selectedDoctor.title"/>
    </template>
    <template v-slot:infoTime>
      <div class="ui-label-time-select">
        {{ wizardData.selectedSlot.start_timestamp | timeslot }} - {{ wizardData.selectedSlot.end_timestamp | timeslot
        }}
      </div>
    </template>
    <template v-slot:form>
      <div class="form-wr__survey">
        <div class="ui-form-heading">Select any options that apply to you…</div>
        <Checkbox v-model="form[survey.question]" v-for="survey in surveyList" :key="survey.id" :label="survey.question"/>
      </div>
    </template>
    <template v-slot:buttons>
      <router-link @click.native="goPrev()" to="/select-booking/step3" tag="button" class="ui-button ui-button--secondary">Back</router-link>
      <router-link to="/select-booking/step5" @click.native="next()" tag="button" class="ui-button ui-button--primary">
        Next
      </router-link>
    </template>
  </WizardWrapper>
</template>

<script>
import WizardWrapper from '@/components/layout/WizardWrapper'
// import DatePick from '@/components/ui/DatePick'
import DoctorInfo from '@/components/ui/DoctorInfo'
import Datepicker from 'vuejs-datepicker'
import Checkbox from '@/components/ui/Checkbox'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import common from '@/api/common'
import StepperHeader from '@/components/ui/StepperHeader'
import mixpanel from 'mixpanel-browser'

export default {
  name: 'StepFour',
  components: {
    Checkbox,
    WizardWrapper,
    Datepicker,
    DoctorInfo,
    StepperHeader
  },
  computed: {
    ...mapGetters(['wizardData', 'surveyList'])
  },
  filters: {
    timeslot: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'loadSurveyList', 'saveSurveyForm']),
    next () {
      this.saveSurveyForm(this.form)
      this.goNext()
    }
  },
  mounted () {
    this.selectedDate = moment(this.wizardData.selectedDate).toDate()
    this.loadSurveyList()
    if (!common.isEmpty(this.wizardData.surveyFormData)) {
      this.form = this.wizardData.surveyFormData
    }
    mixpanel.track('booking_widget.step_4', {
    })
  },
  data () {
    return {
      selectedDate: new Date(),
      form: {}
    }
  },
  watch: {
    form: {
      handler: function (val) {
        this.saveSurveyForm(this.form)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .form-wr__survey {
    display: block;
    position: relative;
    padding: 0 4px;
    @media (min-width: $breakpoint-tablet) {
    }
  }
  .stepper-mobile .step:first-of-type {
    margin-left: calc( 50% - 350px );
  }
</style>
