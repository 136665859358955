import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import { apiUrl } from '../config'
import vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'

Vue.use(Vuex)
Vue.use(Toasted)
Vue.use(vuelidate)
Vue.use(require('vue-moment'))

export default new Vuex.Store({
  state: {
    themeColor: '#dcdc00',
    loadingState: false,
    loadingCount: 0,
    wizard: {
      selectedIssue: {},
      selectedDate: {},
      selectedSlot: {},
      formData: {},
      historyFormData: {},
      surveyFormData: {},
      selectedDoctor: {},
      current_step: 1,
      images: {},
      images_size: {},
      images_load: {},
      images_error: {},
      images_state: {}
    },
    finalResult: {},
    dateList: [],
    historyList: [],
    surveyList: [],
    slotList: [],
    clinic: {},
    doctors: [],
    doctorsFiltered: [],
    issue: [],
    widget_id: null
  },
  mutations: {
    setImageError (state, { id, data }) {
      Vue.set(state.wizard.images_error, id, data)
    },
    setImageLoad (state, { id, data }) {
      Vue.set(state.wizard.images_load, id, data)
    },
    setImageState (state, { id, data }) {
      Vue.set(state.wizard.images_state, id, data)
    },
    setImageSize (state, { id, size }) {
      Vue.set(state.wizard.images_size, id, size)
    },
    setImage (state, { id, image }) {
      Vue.set(state.wizard.images, id, image)
      // state.wizard.images[parseInt(id)] = image
    },
    setThemeColor (state, color) {
      state.themeColor = color
    },
    setClinicData (state, clinicData) {
      state.clinic = clinicData
    },
    editClinicData (state, clinicData) {
      if (clinicData.settings) {
        state.clinic.settings = clinicData.settings
      }
    },
    setIssueList (state, issueList) {
      state.issue = issueList
    },
    setDoctorList (state, doctorList) {
      state.doctors = doctorList
    },
    setCurrentStep (state, step) {
      state.wizard.current_step = step
    },
    setSelectAIssue (state, selectedIssue) {
      state.wizard.selectedIssue = selectedIssue
    },
    setSelectADoctor (state, selectedDoctor) {
      state.wizard.selectedDoctor = selectedDoctor
    },
    setSelectADate (state, selectedDate) {
      state.wizard.selectedDate = selectedDate
    },
    setSlotList (state, slotList) {
      state.slotList = slotList
    },
    setHistoryList (state, historyList) {
      state.historyList = historyList
    },
    setSelectASlot (state, selectedSlot) {
      state.wizard.selectedSlot = selectedSlot
    },
    setFormData (state, formData) {
      state.wizard.formData = formData
    },
    setHistoryFormData (state, formData) {
      state.wizard.historyFormData = formData
    },
    setSurveyList (state, surveyList) {
      state.surveyList = surveyList
    },
    setSurveyFormData (state, formData) {
      state.wizard.surveyFormData = formData
    },
    setLoadingState (state, stateValue) {
      state.loadingState = stateValue
      if (!stateValue) state.loadingCount -= 1
    },
    setLoadingCount (state, value) {
      state.loadingCount = value
    },
    setFinalResult (state, resultValue) {
      state.finalResult = resultValue
    },
    setDateList (state, dateList) {
      state.dateList = dateList
    },
    resetState (state) {
      state = {}
    },
    goPrev (state) {
      state.wizard.current_step--
    },
    goNext (state) {
      state.wizard.current_step++
    },
    start (state) {
      state.wizard.current_step = 1
    },
    setWidgetId (state, id) {
      state.widget_id = id
    }
  },
  getters: {
    themeColor: state => state.themeColor,
    clinicData: state => state.clinic,
    doctorList: state => state.doctors,
    doctorsFilteredList: state => state.doctorsFiltered,
    issueList: state => state.issue,
    dateList: state => state.dateList,
    slotList: state => state.slotList,
    historyList: state => state.historyList,
    surveyList: state => state.surveyList,
    wizardData: state => state.wizard,
    loadingState: state => state.loadingState || state.loadingCount > 0,
    finalResult: state => state.finalResult,
    widgetId: state => state.widget_id,
    getUploadedImageCount: (state) => {
      var photos = []
      for (const key in state.wizard.images) {
        if (state.wizard.images[key])photos.push(state.wizard.images[key])
      }
      return photos.length
    }
  },
  actions: {
    setLoadingCount ({ commit }, data) {
      commit('setLoadingCount', data)
    },
    setImageLoad ({ commit }, { id, data }) {
      commit('setImageLoad', { id, data })
    },
    setImage ({ commit }, { id, image }) {
      commit('setImage', { id, image })
    },
    setImageSize ({ commit }, { id, size }) {
      commit('setImageSize', { id, size })
    },
    setImageState ({ commit }, { id, data }) {
      commit('setImageState', { id, data })
    },
    goPrev ({ commit }) {
      commit('goPrev')
    },
    goNext ({ commit }) {
      commit('goNext')
    },
    start ({ commit }) {
      commit('start')
    },
    setWidgetId ({ commit }, id) {
      commit('setWidgetId', id)
    },
    loadClinicData ({ commit }) {
      // console.log(1)
      if (JSON.stringify(this.state.clinic).length > 2) return
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id).then(res => {
        setTimeout(function () {
        //  commit('setLoadingState', false)
          commit('setClinicData', res.data)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadClinicDataToGetSettings ({ commit }) {
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id).then(res => {
        setTimeout(function () {
          commit('editClinicData', res.data)
          commit('setLoadingState', false)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadIssueData ({ commit }) {
      // console.log(2)
      if (this.state.issue.length > 0) return
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id + '/issues').then(res => {
        setTimeout(function () {
          commit('setIssueList', res.data)
        //  commit('setLoadingState', false)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadDoctorList ({ commit }) {
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id + '/doctors/' + this.state.wizard.selectedIssue.id).then(res => {
        var final = []
        for (var doctor of this.state.doctors) {
          doctor.status = (res.data.find(x => x.id === doctor.id) !== undefined)
          final.push(doctor)
        }
        setTimeout(function () {
          commit('setDoctorList', final)
          commit('setLoadingState', false)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadDateList ({ commit }, date) {
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id + '/doctors/' + this.state.wizard.selectedDoctor.id + '/appointment-dates').then(res => {
        setTimeout(function () {
          commit('setLoadingState', false)
          commit('setDateList', res.data.data)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadSlotList ({ commit }, date) {
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id + '/doctors/' + this.state.wizard.selectedDoctor.id + '/appointments?date=' + date).then(res => {
        setTimeout(function () {
          commit('setLoadingState', false)
          commit('setSlotList', res.data.data)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadHistoryList ({ commit }, date) {
      // console.log(3)
      if (this.state.historyList.length > 0) return
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id + '/medical-history').then(res => {
        setTimeout(function () {
        //  commit('setLoadingState', false)
          commit('setHistoryList', res.data.data)
        }, 100)
      }).catch(e => this.showError(e))
    },
    loadSurveyList ({ commit }, date) {
      // console.log(4)
      if (this.state.surveyList.length > 0) return
      commit('setLoadingState', true)
      Axios.get(apiUrl + '/' + this.state.widget_id + '/survey').then(res => {
        setTimeout(function () {
          commit('setSurveyList', res.data.data)
        }, 100)
        // Since this is the last request at the initial loading increased the loader time
        // and disabled the loaders at loadClinicData , loadIssueData ,loadHistoryList
        setTimeout(function () {
          commit('setLoadingState', false)
        }, 2000)
      }).catch(e => this.showError(e))
    },
    clearImage ({ commit }, id) {
      commit('setImageState', { id, data: false })
      commit('setImageSize', { id, size: 0 })
      commit('setImageLoad', { id, data: 0 })
      commit('setImage', { id, image: null })
    },
    uploadImageBas64 ({ commit }, { id, file }) {
      commit('setImageState', { id, data: true })
      commit('setImageSize', { id, size: 0 })
      commit('setImageLoad', { id, data: 0 })
      Axios.post(apiUrl + '/' + this.state.widget_id + '/upload', {
        image: file
      }, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          commit('setImageLoad', { id, data: percentCompleted })
          commit('setImageSize', { id, size: percentCompleted * (file.length / 100) })
        }
      }).then(e => {
        commit('setImage', { id, image: e.data.success })
        commit('setImageState', { id, data: false })
      }).catch(e => {
        commit('setImageSize', { id, size: 0 })
        commit('setImage', { id, image: null })
        commit('setImageError', { id, data: true })
        setTimeout(function () {
          commit('setImageError', { id, data: false })
          commit('setImageState', { id, data: false })
        }, 3000)
      })
    },
    uploadImage ({ commit }, { id, file }) {
      commit('setImageState', { id, data: true })
      commit('setImageSize', { id, size: 0 })
      commit('setImageLoad', { id, data: 0 })
      var formData = new FormData()
      formData.append('image', file)
      Axios.post(apiUrl + '/' + this.state.widget_id + '/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          commit('setImageLoad', { id, data: percentCompleted })
          commit('setImageSize', { id, size: percentCompleted * (file.size / 100) })
        }
      }).then(e => {
        commit('setImage', { id, image: e.data.success })
        commit('setImageState', { id, data: false })
      }).catch(e => {
        commit('setImageSize', { id, size: 0 })
        commit('setImage', { id, image: null })
        commit('setImageError', { id, data: true })
        setTimeout(function () {
          commit('setImageError', { id, data: false })
          commit('setImageState', { id, data: false })
        }, 3000)
      })
    },
    sellectADoctor ({ commit }, doctor) {
      commit('setSelectADoctor', doctor)
    },
    sellectAIssue ({ commit }, issue) {
      commit('setSelectAIssue', issue)
      commit('setDoctorList', issue.active_clinic_users)
    },
    sellectADate ({ commit }, date) {
      commit('setSelectADate', date)
    },
    sellectASlot ({ commit }, slot) {
      commit('setSelectASlot', slot)
    },
    saveSurveyForm ({ commit }, formData) {
      commit('setSurveyFormData', formData)
    },
    saveHistoryForm ({ commit }, formData) {
      commit('setHistoryFormData', formData)
    },
    saveForm ({ commit }, formData) {
      commit('setFormData', formData)
    },
    async processPayment ({ commit }, { token, captchaToken }) {
      commit('setLoadingState', true)
      var images = []
      for (const key in this.state.wizard.images) {
        if (this.state.wizard.images[key])images.push(this.state.wizard.images[key])
      }
      var res = await Axios.post(apiUrl + '/booking', {
        clinic_id: this.state.widget_id,
        issue_id: this.state.wizard.selectedIssue.id,
        booking_slot_id: this.state.wizard.selectedSlot.id,
        original_date: this.state.wizard.selectedDate,
        name: this.state.wizard.formData.name,
        email: this.state.wizard.formData.email,
        is_current_patient: this.state.wizard.formData.is_patient,
        comment: this.state.wizard.formData.comment,
        survey: JSON.stringify(this.state.wizard.surveyFormData),
        medical_history: JSON.stringify(this.state.wizard.historyFormData),
        mobile_number: this.state.wizard.formData.number,
        patient_images: images,
        date_of_birth: this.state.wizard.formData.dob,
        stripeToken: token,
        captchaToken: captchaToken
      })
      commit('setLoadingState', false)
      commit('setFinalResult', res.data)
      return res.data
    },
    async processPaymentWithoutPayment ({ commit }, captchaToken) {
      commit('setLoadingState', true)
      var images = []
      for (const key in this.state.wizard.images) {
        if (this.state.wizard.images[key])images.push(this.state.wizard.images[key])
      }
      var res = await Axios.post(apiUrl + '/booking-without-payment', {
        clinic_id: this.state.widget_id,
        issue_id: this.state.wizard.selectedIssue.id,
        booking_slot_id: this.state.wizard.selectedSlot.id,
        original_date: this.state.wizard.selectedDate,
        name: this.state.wizard.formData.name,
        email: this.state.wizard.formData.email,
        is_current_patient: this.state.wizard.formData.is_patient,
        comment: this.state.wizard.formData.comment,
        survey: JSON.stringify(this.state.wizard.surveyFormData),
        medical_history: JSON.stringify(this.state.wizard.historyFormData),
        mobile_number: this.state.wizard.formData.number,
        patient_images: images,
        date_of_birth: this.state.wizard.formData.dob,
        captchaToken: captchaToken
      })
      commit('setLoadingState', false)
      commit('setFinalResult', res.data)
      return res.data
    },
    resetData ({ commit }) {
      commit('resetState')
    },
    changeLoadingState ({ commit }, is_true = false) {
      commit('setLoadingState', is_true)
    },
    showError (err) {
      if (!(err.response && err.response.data)) return
      var errorOut = '<ul>'
      if (err.response.data.errors) {
        var errors = err.response.data.errors
        for (const error in errors) {
          const element = errors[error]
          errorOut += '<li>' + element[0] + '</li>'
        }
      }
      errorOut += '</ul>'
      this.$notify('error', 'Something went worng', err.response.data.message + '<br><br>' + errorOut)
    },
    showNotification (variant = null, body = '', title = '') {
      this.$notify(variant, title, body, {
        duration: 3000,
        permanent: false
      })
    }
  },
  modules: {
  }
})
