<template>
  <ContentWrapper formTitle="Book a Video Consultation">
    <template v-slot:default>
      <div class="welcome-wr">
        <h1>
          Welcome to the
          <div>{{clinicData.name}}</div>
          video consultation booking system
        </h1>
        <p>Select the issue you wish to discuss:</p>
        <Dropdown :disabled="disabled" :model.sync="selected">
          <option value="">Click to select...</option>
          <option v-for="issue in filterdIssues" :key="issue.id" :value="issue.id">{{issue.group_issue.issue}}</option>
        </Dropdown>
        <!-- <vue-select class="vue-select4" name="select4"
                :options="issueList.map(x => { return { text: x.issue, value: x.id } })" :model.sync="selected"
                :searchable="true" language="en-US">
        </vue-select> -->
        <div class="price">
          <label v-if="selected && parseFloat(wizardData.selectedIssue.price)>0">Amount:<span>£{{wizardData.selectedIssue.price | currency(2) }}</span></label>
          <label
            v-else-if="selected && parseFloat(wizardData.selectedIssue.price)===0">Amount:<span>Complimentary</span></label>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <router-link to="/select-doc" @click.native="goNext()" :disabled="!selected || selected == 0" tag="button"
                   class="ui-button ui-button--primary">Start
      </router-link>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'
import Dropdown from '@/components/ui/Dropdown'
import common from '@/api/common'
import { mapActions, mapGetters } from 'vuex'
import Api from '@/api/api.js'
// import vueselect2 from 'vue-select2'

export default {
  name: 'BookAppWelcome',
  components: {
    Dropdown,
    ContentWrapper
    // 'vue-select': require('vue-select2/src/vue-select')
  },
  methods: {
    ...mapActions(['setWidgetId', 'goNext', 'loadClinicData', 'loadIssueData', 'sellectAIssue', 'loadHistoryList', 'loadSurveyList', 'setLoadingCount', 'changeLoadingState'])
  },
  computed: {
    ...mapGetters(['clinicData', 'issueList', 'wizardData', 'widgetId']),
    filterdIssues: function () {
      return this.issueList.filter(x => x.active_clinic_users.length > 0)
    }
  },
  filters: {
    currency: function (value, arg) {
      if ((value + '').includes('.')) {
        var val = (Math.round(value * Math.pow(10, arg)) / Math.pow(10, arg)) + ''
        for (let index = 0; index < (arg - val.split('.')[1].length); index++) {
          val += '0'
        }
      } else {
        val = value + '.'
        for (let index = 0; index < arg; index++) {
          val += '0'
        }
      }
      return val
    }
  },
  data () {
    return {
      disabled: true,
      selected: ''
    }
  },
  async mounted () {
    const urlObj = new URL(window.location)
    if (!this.widgetId) {
      if (!urlObj.searchParams.get('id')) {
        this.$router.push('/404')
      } else {
        this.setWidgetId(urlObj.searchParams.get('id'))
        if (this.$router.currentRoute.path !== '/') {
          this.$router.push('/')
          this.start()
        }
      }
    }

    window.document.title = 'Booking Widget | Powered by Chairsyde'

    if (!common.isEmpty(this.wizardData.selectedIssue)) {
      this.selected = this.wizardData.selectedIssue.id
    }
    const vue = this
    try {
      // this.changeLoadingState(true)
      const result = (await Api.checkIsThisClinicRelatedToAFreePlan(this.widgetId)).data
      const status = (await Api.checkIsRelatedGroupBlocked(this.widgetId)).data
      var type = 'not-allowed'
      if (!result.success && !status.success) {
        vue.wizardData.selectedDoctor = {}
        // vue.setLoadingCount(4)
        await vue.loadIssueData()
        await vue.loadClinicData()
        await vue.loadHistoryList()
        await vue.loadSurveyList()
        // this.changeLoadingState(false)
        vue.disabled = false
      } else {
        if (status.success) type = 'blocked'
        this.$router.push(`/no-allowed?type=${type}`)
      }
    } catch (e) {
      this.$router.push('/error')
      // this.changeLoadingState(false)
    }
  },
  watch: {
    selected (val) {
      this.sellectAIssue(this.issueList.find(x => x.id === parseInt(val)))
    },
    filterdIssues (val) {
      this.disabled = false
      // this.changeLoadingState(true)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../style/style";

  .welcome-wr {
    max-width: 460px;
    margin: auto;
    padding: 0 0 2% 0;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    min-height: 60vh;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 26px;
      color: #1a1a1a;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      margin: 0;
      line-height: 2.25;

      div {
        font-weight: 600;
        height: 32px;
        overflow: hidden;
        line-height: 1.2;
      }
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 20px;
      color: #707070;
      margin: 50px 0 16px;
    }

    .price {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: #707070;
      font-size: 16px;
      margin-top: 12px;
      height: 22px;
      overflow: visible;

      span {
        color: #1A1A1A;
        font-weight: 600;
        padding-left: 6px;
      }

      .currency {
        color: #707070;
        font-weight: 600;
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .welcome-wr {
      min-height: 70px;
    }
  }
</style>
