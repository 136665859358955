<template>
  <WizardWrapper>
    <template v-slot:stepper>
      <StepperHeader :value="2"></StepperHeader>
    </template>
    <template v-slot:calendar>
      <datepicker
        maximum-view="day" monday-first="monday-first" full-month-name="full-month-name"
        :disabled-dates="{days: [0,1,2,3,4,5,6]}"
        v-model="selectedDate"
        :inline="true"
        calendar-class="ui-calendar"
        wrapper-class="ui-calendar-wr mobile-hide"
      ></datepicker>
      <label class="mobile-only ui-label">Current Appointment:</label>
      <div class="ui-label-time-select mobile-only">{{ wizardData.selectedSlot.start_timestamp | moment('MMMM DD dddd') }} &nbsp;at&nbsp; {{ wizardData.selectedSlot.start_timestamp | moment('HH:mm') }} - {{ wizardData.selectedSlot.end_timestamp | moment('HH:mm')
        }}</div>
    </template>
    <!--    <template v-slot:calendar>
          <DatePick/>
        </template>-->
    <template v-slot:infoDoc>
      <DoctorInfo :doc-name="wizardData.selectedDoctor.name" :photo="wizardData.selectedDoctor.photo_url" :doc-title="wizardData.selectedDoctor.title"/>
    </template>
    <template v-slot:infoTime>
      <div class="ui-label-time-select">
        {{ wizardData.selectedSlot.start_timestamp | timeslot }} - {{ wizardData.selectedSlot.end_timestamp | timeslot
        }}
      </div>
    </template>
    <template v-slot:form>
      <div class="form-wr__detail">
        <!-- <b-form > -->
        <InputField v-model="$v.form.name.$model" type="text" :class="{'error':$v.form.name.$error}" :validation="$v.form.name" placeholder="Full Name *"></InputField>
        <InputField v-model="$v.form.number.$model" name="phone" type="tel" :class="{'error':$v.form.number.$error}" :validation="$v.form.number" placeholder="Contact Number *"></InputField>
        <InputField v-model="$v.form.email.$model" type="email" :class="{'error':$v.form.email.$error}" :validation="$v.form.email" placeholder="Email address *"></InputField>
        <InputField v-model="$v.form.dob.$model" type="date" :class="{'error':$v.form.dob.$error}" :validation="$v.form.dob" :max="dobLimit" placeholder="Date of birth* (dd-mm-yyyy)"></InputField>

        <div class="form-wr__detail-current">
          <label class="ui-label">Are you a patient at this practice?</label>
          <div>
            <TimeSelect :checked="form.is_patient==true" @click.native="form.is_patient=true" name="current">Yes
            </TimeSelect>
            <TimeSelect :checked="form.is_patient==false" @click.native="form.is_patient=false" name="current">No
            </TimeSelect>
          </div>
        </div>
        <div class="form-wr__detail-desc">
          <label
            class="ui-label"
          >Please share in detail anything that will help prepare for the consultation:</label>
          <textarea maxlength="300" v-model="form.comment" rows="5" class="ui-textarea" placeholder="Type here..."></textarea>
        </div>
        <!-- <div class="form-wr__detail-current">
          <label
            class="ui-label"
          >Please provide a photo if that will help prepare for the consultation:</label>
          <button class="ui-button ui-button--upload">
            <img src="../../assets/add_a_photo-24px.svg"/>
          </button>
        </div> -->
        <!-- </b-form> -->
      </div>
    </template>
    <template v-slot:buttons>
      <router-link
        to="/select-booking/step1"
        tag="button"
        class="ui-button ui-button--secondary"
        @click.native="goPrev()"
      >Back
      </router-link>
      <button tag="button" @click="next()" class="ui-button ui-button--primary">
        Next
      </button>
    </template>
  </WizardWrapper>
</template>

<script>
import WizardWrapper from '@/components/layout/WizardWrapper'
import InputField from '@/components/ui/InputField'
import TimeSelect from '@/components/ui/TimeSelect'
import DoctorInfo from '@/components/ui/DoctorInfo'
import StepperHeader from '@/components/ui/StepperHeader'
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import common from '@/api/common'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, helpers, maxLength } from 'vuelidate/lib/validators'
import mixpanel from 'mixpanel-browser'

const contactNumber = helpers.regex('contactNumber', /^[+]{0,1}[0-9 ]*$/)

export default {
  name: 'StepTwo',
  components: {
    TimeSelect,
    InputField,
    WizardWrapper,
    Datepicker,
    StepperHeader,
    DoctorInfo
  },
  data () {
    return {
      dobLimit: moment().subtract(5, 'year').format('YYYY-MM-DD'),
      selectedDate: new Date(),
      formValid: false,
      form: {
        name: '',
        number: '',
        email: '',
        dob: '',
        is_patient: false
      }
    }
  },
  filters: {
    timeslot: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required
      },
      number: {
        required,
        contactNumber,
        minLength: minLength(7),
        maxLength: maxLength(16)
      },
      email: {
        required,
        email
      },
      dob: {
        required
      }
    }
  },
  computed: {
    ...mapGetters(['wizardData'])
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'saveForm', 'loadClinicDataToGetSettings']),
    async next (e) {
      // e.preventDefault()
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.$anyError) {
        this.saveForm(this.form)
        await this.loadClinicDataToGetSettings()
        this.$router.push('/select-booking/step2-imageupload')
        this.goNext()
      }
    }
  },
  mounted () {
    this.selectedDate = moment(this.wizardData.selectedDate).toDate()
    if (!common.isEmpty(this.wizardData.formData)) {
      this.form = this.wizardData.formData
    }
    mixpanel.track('booking_widget.step_2', {
    })
  },
  watch: {
    form (val) {
      // var error = false
      this.formValid = (val.name && val.number && val.email && val.dob)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../style/style";

  .form-wr__detail {
    display: block;
    position: relative;
    padding: 0 12px 0 0;

    &-current {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0 10px;
      > div {
        display: flex;
        .ui-button--time-select{
          margin-left: 10px;
        }
      }

      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
      }
    }

    &-desc {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .ui-label {
        text-align: center;
        @media (min-width: $breakpoint-tablet) {
          text-align: left;
        }
      }
    }
  }
  .stepper-mobile .step:first-of-type {
    margin-left: calc( 50% - 150px );
  }
</style>
