<template>
  <div id="loading-bg" v-if="loadingState">
    <div class="loading-logo">
      <img src="../assets/logo.png" style="width: 50% !important;" alt="Logo" />
      <h4 class="mt-3" v-if="error" >{{error}}</h4>
    </div>
    <div v-if="!error" class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
  <div class="mainContainer" v-else-if="landingPageData">
    <header>
      <div class="container-fluid">
        <!-- navigation-->
        <div
          class="navigation d-flex justify-content-center align-items-center"
        >
          <div class="logo mr-auto">
            <img v-if="!landingPageData.logo_url.endsWith('clinic_users/Default.png')"
              class="img-fluid"
              :src="landingPageData.logo_url"
              alt="logo"
            />
            <h3 v-else >{{landingPageData.name}}</h3>
          </div>
          <div class="cta">
            <a href="#booking">
              <button class="cta-btn">Book an Appointment</button>
            </a>
          </div>
        </div>
        <!-- hero -->
        <section class="h-100 hero-sec">
          <div class="container h-100 pt-5">
            <div class="row h-100 justify-content-between align-items-center">
              <div class="col-lg-5 h-50">
                <div
                  class="d-flex flex-column h-100 justify-content-between align-items-center align-items-lg-start text-center text-lg-left"
                >
                  <h1 class="font-weight-bold">
                    Book your virtual consultation
                  </h1>
                  <a href="#start" >
                    <button class="cta-btn">Let's Get Started</button>
                  </a>
                </div>
              </div>
              <div class="col-lg-5 mt-3 mt-lg-0">
                <img
                  src="../assets/landing/hero.png"
                  class="h-100 w-100 img-fluid"
                  alt="hero"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </header>

    <!-- how it works -->
    <section class="h-100 how-sec" id="start">
      <div class="container pt-5 pb-0">
        <div
          class="d-flex flex-column justify-content-center align-items-center text-center"
        >
          <div class="col-sm-6">
            <h3 class="font-weight-bold text-uppercase">How it works</h3>
          </div>
          <div class="my-3">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="d-flex flex-column justify-content-around align-items-center rounded px-lg-5 py-md-3 col-box"
                >
                  <span class="mb-1 p-4" style="position: relative">
                    <img
                      src="../assets/landing/top-icon-1.png"
                      class="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <h4>Step 1</h4>
                  <div class="my-2">
                    <h2>Book your date<br />and time</h2>
                    <p>
                      Choose a time and date for your virtual consultation that
                      suits you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="d-flex flex-column justify-content-around align-items-center rounded px-lg-5 py-md-3 col-box"
                >
                  <span class="mb-1 p-4" style="position: relative">
                    <img
                      src="../assets/landing/top-icon-2.png"
                      class="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <h4>Step 1</h4>
                  <div class="my-2">
                    <h2>Upload your<br />photos</h2>
                    <p>
                      Upload photos of your smile. Photos and Video Guides are
                      there to help you get the perfect shots.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="d-flex flex-column justify-content-around align-items-center rounded px-lg-5 py-md-3 col-box"
                >
                  <span class="mb-1 p-4" style="position: relative">
                    <img
                      src="../assets/landing/top-icon-3.png"
                      class="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <h4>Step 1</h4>
                  <div class="my-2">
                    <h2>Video call with<br />your dentist</h2>
                    <p>
                      We’ll be in touch to book a virtual consultation to
                      discuss the results and options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <a href="#booking">
                  <button class="nl-btn">Get Started</button>
                </a>
              </div>
            </div>
            <div class="row justify-content-center mt-5">
              <div class="col-md-8 text-center mt-4">
                <div class="vid-block">
                  <div class="embed-responsive embed-responsive-4by3">
                    <!-- <video
                      class="chairsyde_video"
                      src="https://chairsyde.com/wp-content/uploads/2021/01/ChairsydeSocialMediaVideo.mp4"
                      controls
                      muted=""
                      playsinline=""
                      width="auto"
                      height="auto"
                      preload="metadata"
                    ></video> -->
                    <iframe src="https://player.vimeo.com/video/600138256?h=d611111543&autoplay=1&loop=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- benefits -->
    <section class="h-100 benefits-sec bg-ch-black">
      <div class="container py-5">
        <div
          class="d-flex flex-column justify-content-center align-items-center text-center"
        >
          <div class="col-sm-8">
            <h3 class="font-weight-bold txt-ch-neutral text-uppercase">
              Benefits of virtual consultations
            </h3>
            <h4 class="txt-ch-neutral">
              As the COVID-19 outbreak unfolds, our commitment to providing care
              to both new and existing patients has never been more important.
              Virtual care has been recommended as an effective way to help
              avoid exposure and spread of viruses.
            </h4>
          </div>
          <div class="my-3">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="d-flex flex-column justify-content-around align-items-center rounded px-lg-5 col-box"
                >
                  <span class="mb-1 p-4" style="position: relative">
                    <img
                      src="../assets/landing/btm-icon-1.png"
                      class="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <div class="my-0">
                    <p class="txt-ch-neutral">
                      We can answer any questions you have about the treatment
                      process, cost and payment plans.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="d-flex flex-column justify-content-around align-items-center rounded px-lg-5 col-box"
                >
                  <span class="mb-1 p-4" style="position: relative">
                    <img
                      src="../assets/landing/btm-icon-2.png"
                      class="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <div class="my-0">
                    <p class="txt-ch-neutral">
                      Safe and secure consultations from the comfort of your own
                      home.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="d-flex flex-column justify-content-around align-items-center rounded px-lg-5 col-box"
                >
                  <span class="mb-1 p-4" style="position: relative">
                    <img
                      src="../assets/landing/btm-icon-3.png"
                      class="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <div class="my-0">
                    <p class="txt-ch-neutral">
                      Save time by phoning your dentist from comfort of home
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- about -->
    <section class="h-100 about-sec">
      <div class="container py-5">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <div class="my-3">
            <div class="row">
              <div class="d-flex flex-wrap align-items-center">
                <div class="col-md-6">
                  <div
                    class="d-flex flex-column justify-content-around align-items-center rounded p-3"
                  >
                    <div class="about-txt text-md-left">
                      <h3 class="font-weight-bold text-uppercase">
                        About your dentist
                      </h3>
                      <p v-if="landingPageData" style="white-space: pre-line;">
                        {{landingPageData.notes}}
                      </p>
                      <a href="#booking">
                        <button class="nl-btn">Get Started</button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="d-flex flex-column justify-content-around align-items-center rounded p-3"
                  >
                    <div class="about-img pr-5 pb-5">
                      <div class="about-img__top">
                        <img
                          src="../assets/landing/about-2.png"
                          class="img-fluid rounded-circle"
                          alt="icon"
                        />
                      </div>
                      <div class="about-img__btm">
                        <img
                          src="../assets/landing/about-1.png"
                          class="img-fluid rounded-circle"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center mt-5" id="booking">
              <div class="col-sm-12">
                <embed type='text/html' :src="'/?id='+this.$route.params.id" width='100%' height='600'>
                <!-- <div class="booking-widget text-center mt-5">
                  <div class="embed-responsive embed-responsive-16by9">
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- footer -->
    <section class="bg-ch-black footer">
      <div class="container">
        <footer class="py-5">
          <div class="row">
            <div class="col-md-12 text-center">
              <p class="txt-ch-neutral font-weight-bold mb-3">Powered by</p>
              <img
                src="../assets/landing/ch-logo.png"
                class="img-fluid mb-5"
                alt="ch-logo"
              />
              <p class="small txt-ch-neutral mb-1">
                {{landingPageData.address_1}},{{landingPageData.address_2}},{{landingPageData.city}} {{landingPageData.post_code}}
              </p>
              <p class="small txt-ch-neutral">
                T {{landingPageData.phone_1}} E
                <a
                  class="txt-ch-neutral"
                  :href="'mailto:'+landingPageData.email"
                  >{{landingPageData.email}}</a
                >
              </p>
            </div>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  data () {
    return {
      landingPageData: {},
      loadingState: true,
      error: null
    }
  },
  async mounted () {
    this.loadingState = true
    window.document.title = 'Landing Page | Powered by Chairsyde'
    try {
      this.landingPageData = (
        await Api.loadLandingPage(this.$route.params.id)
      ).data.success
      window.document.title = `${this.landingPageData.name} | Powered by Chairsyde`
      document.querySelector('meta[name="title"]').setAttribute('content', window.document.title)
      document.querySelector('meta[name="description"]').setAttribute('content', 'You can now speak to our dental team from the comfort of your own home! Booking a virtual consultation is quick and easy. One of our team members will be delighted to help in your journey to creating your perfect smile!')
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.landingPageData.name)
      this.loadingState = false
    } catch (error) {
      const response = error.response.data
      if (response.errors) {
        this.error = 'Sorry this feature is disabled'
      } else {
        this.error = 'Sorry this link is invalid'
      }
      // if ()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../style/landing/landing";
</style>
