import { apiUrl } from '../config'
import Axios from 'axios'
import store from '@/store/index'

export default {
  loadLandingPage: (id) => Axios.get(`${apiUrl}/${id}/landing-page`),

  loadClinicData ({ commit }) {
    Axios.get(apiUrl + '/' + store.getters.widgetId).then(res => {
      setTimeout(function () {
        commit('setClinicData', res.data)
      }, 1500)
    })
  },

  loadDoctorsData ({ commit }) {
    Axios.get(apiUrl + '/' + store.getters.widgetId).then(res => {
      setTimeout(function () {
        commit('setClinicData', res.data)
      }, 1500)
    })
  },

  loadIssueData ({ commit }) {
    Axios.get(apiUrl + '/' + store.getters.widgetId).then(res => {
      setTimeout(function () {
        commit('setClinicData', res.data)
      }, 1500)
    })
  },
  checkIsThisClinicRelatedToAFreePlan: (clinicId) => Axios.post(`${apiUrl}/${clinicId}/verify`, { id: clinicId }),
  checkIsRelatedGroupBlocked: (clinicId) => Axios.post(`${apiUrl}/${clinicId}/verify-status`, { id: clinicId })

}
